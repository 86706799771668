import React from 'react';
import TemplatesPage from '../pagesComponents/TemplatesPage';

export default function Dg24Page({path}) {
  if (typeof window !== "undefined" && window.localStorage)
    window.localStorage.setItem("uiLang", "es");

  return (
    <TemplatesPage appContextValue={{language: "es", path, templatesPage: true}} />
  );
}
